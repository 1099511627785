import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { ActivatedRoute } from '@angular/router';
import { ethers } from 'ethers';
import { ToastrService } from 'ngx-toastr';
import { ConnectWalletComponent } from '../connect-wallet/connect-wallet.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  hidestar: boolean = false;
  contractaddress: any;
  url_id: string = '';
  account: any;
  isConnected: boolean = false;
  playerdetails: any;
  availableToWithdraw: any;
  userbalance: any;
  contractInfo: any;
  isBusd: any = true;
  base_url = location.origin;
  currency = 'BNB';
  value: number | undefined;
  isScrolled: boolean = false;
  constructor(
    private cs: GlobalService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) {}
  

  ngOnInit(): void {
    localStorage.setItem('type', "2");

    setTimeout(() => {
      this.hidestar = false;
    }, 5000);
    this.getaccount();
    let that: any = this;
    window.addEventListener(
      'message',
      function (e: any) {
        if (e.data.message && e.data.message.action == 'setAccount') {
          if (that.account != e.data.message.data.address) {
            that.account = e.data.message.data.address;
            that.getaccount();
            that.emptyamount = false;
            that.erroroccured = false;
            that.erroroccuredaccount = false;
          }
        }
      }.bind(this)
    );
    this.isBusd = localStorage.getItem('type') ?? '1';

    if (this.isBusd == "2") {
      this.currency = 'Matic';
      this.value = 10;
    } else {
      this.currency = 'BNB';
      this.value = 0.05;
    }

    this.url_id = this.cs.getUplineid(this.route);
  }

  async getaccount() {
    try {
      this.cs.init();

      this.cs.getWalletObs().subscribe((data: any) => {
        if (this.cs.isValidAddress(data)) {
          this.account = data;
          this.isConnected = true;
          let that = this;
          setInterval(function () {
            
            that.loadData();
          }, 100000);

          this.loadData();
        } else {
          this.isConnected = false;
        }
      });
    } catch (e) {
      this.isConnected = false;
    }
  }

  async connect() {
    // if(walletType==1)
    // this.cs.connectToWallet();
    // else
    // this.cs.connectToWalletConnect();
    let dialogRef = this.dialog.open(ConnectWalletComponent, {
      height: '320px',
      width: '340px',
      panelClass: 'custom-modalbox',
    });
    // this.cs.connectContract();
  }

  async loadData() {
    this.isConnected = true;
     try {
      
       this.playerdetails = await this.cs.UserInfo();
       console.log('********************************');
       console.log(parseInt(this.playerdetails.totalInvested, 16));
       this.contractInfo = await this.cs.contractInfo();
       this.cs
         .getBalanceByAddress(this.account)
         .then((balance: any) => (this.userbalance = balance));
     } catch (error) {
      console.log(error);
      
     }
  }

  async deposit(amount: any) {
    ;
    try {
      let amountInEth = ethers.utils.parseEther(amount);
      if (localStorage.getItem('type') == '2') {
        let allowance: any = await this.cs.checkAllowance(
          this.account,
          amountInEth
        );
          ;
        if (allowance.status && allowance.allowance) {
          await this.cs.deposit(this.url_id, amountInEth);
        } else if (allowance.status && !allowance.allowance) {
          let allowToken: any = await this.cs.approveToken(amountInEth);
          if (allowToken.status) {
            await allowToken.hash.wait(3);
            await this.cs.deposit(this.url_id, amountInEth);
          }
        }
      } else {
        await this.cs.deposit(this.url_id, amountInEth);
      }
    } catch (e : any) {
      ;
      this.toastr.error(e.data.message);
    }
  }

  async withdraw() {
    try {
      await this.cs.withdraw();
    } catch (e:any) {
      this.toastr.error(e.data.message);
    }
  }

  async setContractType(type: number) {
    localStorage.setItem('type', "2");
    location.reload();
  }

  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastr.success('Copied!');
  }
}

