import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contract, ethers } from "ethers";
import Web3 from 'web3';
import { Observable, BehaviorSubject } from 'rxjs';
import WalletConnectProvider from "@walletconnect/web3-provider";


function _window(): any {
  return window;
}



declare let require: any;
declare let window: any;
// const tokenAbi = require('./../../assets/token.json');
const tokenAbi = require('./../../assets/tokenAbi.json');
const stakingAbi = require('./../../assets/stakingBusd.json');
const providerOptions = {
  rpc: {
    56: "https://bsc-dataseed1.binance.org",
 },
 network: "binance",
 chainId: 56,
};

const provider = new WalletConnectProvider(providerOptions);


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public stakingBUSDAddress: string = "0x962823a89bBF6A0b899083B421De5D13034B8e7e";
  public owner_address: string = "0x0000000000000000000000000000000000000000";
  public token_address: string = "0xd3521B5dD10061245ABf863A3ae36732171084c3";
  public _web3: any;
  stakingContract!: Contract;
  // stakingContractBUSD: any;
  BUSDContract: any;
  _account: any;
  counter = 1;
  http!: HttpClient;
  provider: any;
  signer: any;

  public walletDetails$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor() {

  }

  getWalletObs(): Observable<any> {
    return this.walletDetails$.asObservable();
  }

  setWalletObs(profile: any) {
    this.walletDetails$.next(profile);
  }


  init(): void {
    let isConnected = localStorage.getItem('wallet') == "1";
    if (isConnected) {
      if((localStorage.getItem('wallet') ?? "1")=="1"){
      this.connectContract();
      }
      else if((localStorage.getItem('wallet') ?? "1")=="2")
      {
        this.connectAccountWalletConnect();
      }
    }
  }

  async connectContract() {
    // var web3 = new Web3( 'https://bsc-dataseed1.binance.org:443/');
    // this.commonContract =new web3.eth.Contract(tokenAbi,this.address);

    if ((typeof this.nativeWindow.ethereum) !== undefined && (typeof this.nativeWindow.ethereum) != undefined && (typeof this.nativeWindow.ethereum) != 'undefined') {
      await this.nativeWindow.ethereum.enable();
      this.provider = new ethers.providers.Web3Provider(this.nativeWindow.ethereum);

      await this.getAccountAddress();
      localStorage.setItem('wallet', '1');

      this.nativeWindow.ethereum.on("accountsChanged", (accounts: string[]) => {
        this.connectContract();
        location.reload();
      });

      this.nativeWindow.ethereum.on("networkChanged", (code: number, reason: string) => {
        this.connectContract();
        location.reload();
      });
    }
  }


  async connectAccountWalletConnect() {
    await provider.enable().then(() => console.log("first call resolved"))
    .catch(() => provider.disconnect());;

    this.provider = new ethers.providers.Web3Provider(provider);
    await this.getAccountAddress();
    localStorage.setItem('wallet', '2');

      provider.on("accountsChanged",async (accounts: string[]) => {
        location.reload();
      });

      // Subscribe to session disconnection
      provider.on("disconnect", (code: number, reason: string) => {
        location.reload();
      });

      // Subscribe to session disconnection
      provider.on("networkChanged", (code: number, reason: string) => {
        this.connectAccountWalletConnect();
      });

  }


  async getAccountAddress() {
    this.signer = this.provider.getSigner();
    this._account = await this.signer.getAddress();
    var network = await this.provider.getNetwork();
    localStorage.setItem('address', this._account);
    if (network.chainId == 97) {
      this.stakingContract = new ethers.Contract(this.stakingBUSDAddress, stakingAbi, this.signer);
      this.BUSDContract = new ethers.Contract(this.token_address, tokenAbi, this.signer);

    }
    this.setWalletObs(this._account);
  }

  get nativeWindow(): any {
    return _window();
  }

  async getAccount() {
    return this._account;
  }

  public async getUserBalance() {
    let account = await this.getAccount();
    return account;
  }

  public isValidAddress(address: any) {
    return ethers.utils.isAddress(address);
  }

  getUplineid(route: any): string {
    let url_id = this.owner_address;
    if (route.snapshot.url.length > 1)
      url_id = route.snapshot.url[1].path;


    return url_id;

  }



  public async getBalance(account: any): Promise<any> {

    return new Promise((resolve, reject) => {
      this.stakingContract.methods.getBalance(account).then(function (result: any) {
        if (result == null) {
          reject([]);
        }
        resolve(result);
      });
    });
  }



  public async UserInfo(): Promise<any> {
    return await this.stakingContract.userInfo(this._account);
  }

  public async contractInfo(): Promise<any> {
    return await this.stakingContract.contractInfo();
  }


  async getBalanceByAddress(userAddress: string) {
    var balance: any = await this.provider.getBalance(userAddress);
    return balance;
  }


  public async userInfoBankA(userAddress: any): Promise<any> {
    return await this.stakingContract.userInfoBankA(userAddress);
  }

  public async userInfoBankB(userAddress: any): Promise<any> {
    return await this.stakingContract.userInfoBankB(userAddress);
  }

  public async userInfoBankC(userAddress: any): Promise<any> {
    return await this.stakingContract.userInfoBankC(userAddress);
  }

  public async getUserPercentRate(userAddress: any): Promise<any> {
    return await this.stakingContract.getUserPercentRate(userAddress);
  }


  public async getUserDividends(userAddress: any): Promise<any> {
    return await this.stakingContract.getUserDividends(userAddress);
  }
  weitoether(amount: any) {
    return amount / 1000000000000000000;
  }




  public async withdraw(): Promise<any> {
   await this.stakingContract.withdraw();
  }

  public async deposit(upline: any, amount: any) {
    ;
    await this.stakingContract.deposit(amount,upline, { value: 0 });
  }


  async checkAllowance(userAddress:any,amount:any) {
    var promise = new Promise(async (resolve, reject) => {
      try {
     this.BUSDContract.allowance(userAddress, this.stakingBUSDAddress)
      .then(async function (allowanceAmount:any) {
        
        if(Number(allowanceAmount)>=Number(amount)){
          resolve({ status: true,allowance:true });
        }
        else{
          resolve({status:true,allowance:false})
        }
        })
      }
      catch (e) {
        console.log(e)
        reject({ hash: e, status: false });
      }
    });
    return promise
  }

  async approveToken(amount:any)
  {
    var promise = new Promise(async (resolve, reject) => {
      try {
        let tx =await this.BUSDContract.approve(this.stakingBUSDAddress, (amount).toString())
          resolve({hash:tx,status:true,allowance:false})
      }
      catch (e) {
        reject({ hash: e, status: false });
      }
    });
    return promise
  }





}
